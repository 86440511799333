<template>
  <div class="body fixed mobile ">
    <section class="header store_register">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="$router.push('/profile')">
          <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg" />
        </a>
      </div>
      <span class="fL nowrap">{{ $t('contact-cs') }}</span>
      <div class="flexH width right">
        <!-- <a class="textButton fM third">草稿暫存</a> -->
      </div>
    </section>
    <div class="main">
      <span class="mt-2 text-lg">
        {{ $t('contact-cs-desc') }}
      </span>
      <a href="tel+05-5523000" class="button rounded mt-2"><span>{{ $t('call-cs') }}</span></a>
      <a href="https://m.me/YunlinCoin" class="mt-2 fb button white rounded">
        <div class="icon">
          <font-awesome-icon :icon="['fas', 'gift']" color="#6b2df5" />
        </div>

        <span>{{ $t('go-facebook') }}</span>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomerService",
  // beforeRouteEnter(to, from, next) {
  //   // called before the route that renders this component is confirmed.
  //   // does NOT have access to `this` component instance,
  //   // because it has not been created yet when this guard is called!
  //   console.log(to, from);
  //   next((vm) => {
  //     let that = vm;
  //     console.log('user: ', that.user);
  //     if (that.user && that.user.userId) {
  //       vm.getUserPoints()
  //         .then((res) => {
  //           that.$store.commit('updateUserPoints', res);
  //         })
  //         .catch((err) => {
  //           console.log('err: ', err);
  //           next('/error');
  //         });
  //       vm.$store.commit(
  //         'updateUserInfo',
  //         Object.assign({}, vm.userInfo, {
  //           myReferralCode: vm.user.user.myReferralCode,
  //         })
  //       );
  //       vm.myReferralCode = vm.user.user.myReferralCode;
  //     } else {
  //       next('/error');
  //     }
  //   });
  // },
};
</script>
<style scoped lang="scss">
button {
  border: 0px;
}

a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &.fb {
    border: 1px solid #6b2df5;
  }
}
</style>